var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    [
      _c("sub-menu", {
        attrs: {
          name: _vm.menuLabel,
          "menu-items": _vm.pages,
          showDropDown: "",
        },
        on: { "sub-menu-click": _vm.onMenuItemClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }